import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "company": "McAfee",
  "title": "Software Developer Intern",
  "time": "Winter 2020",
  "index": 1,
  "type": "position",
  "hidden": false,
  "splash": "Reducing install time of McAfee security solutions including Windows System Security and McAfee Gamer Security"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://www.mcafee.com/"
      }}>{`McAfee`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` Jan - Apr 2020`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`C++`}</inlineCode>{` `}<inlineCode parentName="p">{`Angular`}</inlineCode>{` `}<inlineCode parentName="p">{`Bash`}</inlineCode></p>
    <p><strong parentName="p">{`Location:`}</strong>{` Ontario, Canada`}</p>
    <p><strong parentName="p">{`Team:`}</strong>{` Installer Team`}</p>
    <hr></hr>
    <p>{`At McAfee I worked on the `}<em parentName="p">{`Installer team`}</em>{`. My team was concerned with all product installers but particularly with the main `}<em parentName="p">{`Windows System Security`}</em>{` installer.`}</p>
    <p>{`Over the term I worked to improve the performance and reliability of our core product installer. I removed unnecessary forced device reboots throughout the install process. I also profiled the install process via binary instrumentation to isolate install time bottlenecks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      